import {
  GLSelectSearchOptions,
  RegisteredDevice,
  useI18n,
  useRegisterDeviceStore,
  useToast,
} from "@group-link-one/grouplink-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  ChannelData,
  EditDeviceBody,
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useGoogleMapsService } from "../../../Services/googleMapsService/useGoogleMapsService";
import { useDeviceListStore } from "../store/device-list-store";

export const useModalEditDevice = () => {
  const { state: registerDeviceState, actions: registerDeviceActions } =
    useRegisterDeviceStore();

  const { state: deviceListState } = useDeviceListStore();

  const { googleMapsIsLoaded, getSuggestions, getPlaceDetails } =
    useGoogleMapsService();

  const { updateDevice, registerDevice } = useDeviceListService();
  const { addToast } = useToast();

  const { t } = useI18n();

  const queryClient = useQueryClient();

  async function onEditDevice(newData: RegisteredDevice) {
    try {
      if (!registerDeviceState.deviceId) return;

      const channelData: ChannelData = {
        channel: 0,
        channel_name: newData.deviceName || "",
        remote_id: newData.remoteId || "",
        current_reading: "0",
        pulse_factor_reference: "null",
        read_whitelabel: "00000000000000",
      };

      const body: EditDeviceBody = {
        channel_data: [channelData],
       // device_measurement_category: registerDeviceState.deviceMeasurementCategory,
        device_id: Number(registerDeviceState.deviceId),
        gps_location: {
          address: registerDeviceState.addressSelectedInfo.address || "",
          city: newData.city || "",
          state: newData.state || "",
          number: newData.complement || "",
          country: "",
          country_code: "",
          neighborhood: newData.state || "",
          zipcode: newData.cep.replace("-", "") || "",
          display_address:
            registerDeviceState.addressSelectedInfo.address || "",
          lat: registerDeviceState.addressSelectedInfo.lat,
          long: registerDeviceState.addressSelectedInfo.lng,
          acc: 0,
        },
        is_draft: false,
       // read_device_type_hex: "0x08",
        //read_device_use_case_hex: "0x03"
      };
      
      if(deviceListState.modalType === "register") {
        body.device_measurement_category = "energy"
        body.read_device_type_hex = "0x08"
        body.read_device_use_case_hex = "0x03"
      }

      deviceListState.modalType === "register"
        ? await registerDevice(body)
        : await updateDevice(body)

      addToast({
        title: t("deviceList.modalEdit.onSuccess.title"),
        message: t("deviceList.modalEdit.onSuccess.description"),
        type: "success",
      });

      if(deviceListState.modalType === "register") refetchDeviceListActivated();
      
      return { ok: true };
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.message || error.message;
        addToast({
          title: t("deviceList.modalEdit.onError.title"),
          message: errorMessage,
          type: "error",
        });
      }

      return { ok: false };
    }
  }

  const { mutateAsync: onEditDeviceFn, isPending } = useMutation({
    mutationFn: onEditDevice,
  });

  const { data: filteredAddresses, isLoading } = useQuery({
    queryKey: ["filtered-addresses", registerDeviceState.addressSearch],
    queryFn: async () => {
      if (!registerDeviceState.addressSearch || !googleMapsIsLoaded) return [];

      const predictions = await getSuggestions(
        registerDeviceState.addressSearch
      );

      return predictions;
    },
  });

  async function onPlaceSelect(placeId?: string) {
    if (!placeId) return;

    const placeDetails = await getPlaceDetails(placeId);

    if (!placeDetails) return;

    const addressOptions = {
      address: placeDetails.formatted_address || "",

      cep:
        placeDetails.address_components?.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "",

      city:
        placeDetails.address_components?.find((component) =>
          component.types.includes("administrative_area_level_2")
        )?.long_name || "",

      state:
        placeDetails.address_components?.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.short_name || "",

      lat: placeDetails.geometry?.location?.lat() || 0,
      lng: placeDetails.geometry?.location?.lng() || 0,
    };

    registerDeviceActions.setAddressSelectedInfo(addressOptions);
  }

  function formatAddress(
    filteredAddressesToFormat: google.maps.places.AutocompletePrediction[]
  ) {
    const options: GLSelectSearchOptions[] = filteredAddressesToFormat.map(
      (address) => {
        return {
          text:
            address.description.length > 60
              ? address.description.slice(0, 60) + "..."
              : address.description,
          id: String(address.place_id),
        };
      }
    );

    registerDeviceActions.setFilteredAddressesFormatted(options);

    return options;
  }

  async function autoCompleteOnEdit() {
    if (!registerDeviceState.addressSearch || !googleMapsIsLoaded) return [];

    const predictions = await getSuggestions(registerDeviceState.addressSearch);

    const predictionsFormatted = formatAddress(predictions);

    onPlaceSelect(predictionsFormatted[0].id);
  }

  function refetchDeviceListActivated() {
    queryClient.invalidateQueries({
      queryKey: ["devices-activated-last-readings"],
    });

    queryClient.invalidateQueries({
      queryKey: ["devices-pendings-last-readings"],
    });

    queryClient.invalidateQueries({
      queryKey: ["bignumbers-device-list"],
    });
  }

  useEffect(() => {
    if (filteredAddresses) {
      formatAddress(filteredAddresses);
    }
  }, [filteredAddresses]);

  useEffect(() => {
    registerDeviceActions.setAddressIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    registerDeviceActions.setIsLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    if (registerDeviceState.modalIsOpen) autoCompleteOnEdit();
  }, [registerDeviceState.modalIsOpen, registerDeviceState.addressSearch]);

  useEffect(() => {
    if (registerDeviceState.modalIsOpen) {
      registerDeviceActions.setAddressSearch(
        registerDeviceState.addressSelectedInfo.address
      );
    }
  }, [
    registerDeviceState.addressSelectedInfo,
    registerDeviceState.modalIsOpen,
  ]);

  return {
    registerDeviceState,
    onPlaceSelect,
    onEditDeviceFn,
    autoCompleteOnEdit,
  };
};
